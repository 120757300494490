<template>
    <div>
        <div class="form-row" v-if="!customerAmenities.length">
            <div class="bg-red-100 p-4 text-xs w-full text-red-500">
                <icon name="info" class="h-6 w-6 inline mr-4"></icon>You do not have any amenity added yet.
            </div>
        </div>

        <div class="form-row form-row_grid">
            <div class="form-col">
                <label for="headline">section title</label>
                <text-input
                  id="headline"
                  v-model="amenities.headline"
                />
                <span v-if="hasError('amenities.headline')" class="form-error">{{ showError('amenities.headline') }}</span>

                <label for="description" class="mt-8">description</label>
                <text-input
                  id="description"
                  v-model="amenities.description"
                  multiline
                />
                <span v-if="hasError('amenities.description')" class="form-error">{{ showError('amenities.description') }}</span>
            </div>
            <div class="form-col">
                <label for="type">amenity types</label>
                <dropdown
                    id="type"
                    :options="options"
                    v-model="amenities.type"
                ></dropdown>
                <span class="form-hint">You can show all amenities, or limit them to just apartment or community.</span>
                <span v-if="hasError('amenities.type')" class="form-error">{{ showError('amenities.type') }}</span>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";
import Icon from "@/components/ui/Icon";
import Dropdown from "@/components/ui/Dropdown";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from "@/components/ui/TextInput";

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, Dropdown, Icon, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            amenities: {
                headline: null,
                description: null,
                type: null,
            },
            options: [
                { key: 'All', value: 'All'},
                { key: 'Apartment', value: 'Apartment'},
                { key: 'Community', value: 'Community'},
            ],
            isProcessing: false,
        }
    },
    computed: {
        ...mapGetters({
            customerAmenities: 'amenities/getCustomerAmenities',
        }),
    },
    methods: {
        save() {
            if (this.validator.passes()) {
                this.isProcessing = true;
                this.$emit('save-page-widget', Object.assign({}, this.amenities));
            }
        },
    },
    validations: {
        'amenities.headline' : 'required',
        'amenities.description' : 'required',
        'amenities.type' : 'required',
    },
    created() {
        this.initValidator();

        if(this.values.length) {
            Object.assign(this.amenities, {
                headline: this.getValue('headline'),
                description: this.getValue('description'),
                type: this.getValue('type'),
            });
        }
    }
}
</script>
